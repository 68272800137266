import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import ImagePreview from './ImagePreview';

const ArticleForm = () => {
  const {id} = useParams();
  
  const [isEditMode, setIsEditMode] = useState(false); 
  const [types, setTypes] = useState([]);
  const [article, setArticle] = useState({
    id: '',
    name: '',
    link: '',
    recipe: '',
    type: '',
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    recipe: '',
    type: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(config.API_URL+'/api/data/' + id);
        const data = await response.json();
        // console.log(data);
        setArticle(data);
      } catch (error) {
        console.error('Erreur lors de la requête :', error);
      }
    };
    
    fetchData();
  }, [id]);
  

  useEffect(() => {
    // Assurez-vous que l'article est chargé avant de mettre à jour les valeurs du formulaire
    if (article) {
      setFormData({
        name: article.name || '',
        recipe: article.recipe || '',
        type: article.type || '',
      });
    }
  }, [article]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchTypes = async () => {
    try {
      const response = await fetch(config.API_URL+'/api/types');
      if (!response.ok) {
        throw new Error("Erreur réseau");
      }
      const data = await response.json();
      setTypes(data);
    } catch (error) {
      console.error("Erreur lors de la requête:", error);
    }
  };
  
  useEffect(() => {
    fetchTypes();
  }, []);
  
  
  if (!article) {
    return <div>Loading...</div>;
  }

  const { name, link, recipe, type } = article;

  const handleSubmit =  (e) => {
    e.preventDefault();
    let nameArticle = document.getElementById('nameArticle').value;
    let recipeArticle = document.getElementById('recipeArticle').value;
    let imageArticle = document.getElementById('imageArticle').files[0];
    let typeArticle = document.getElementById('typeArticle').value;

    // Vérifier si les valeurs sont nulles ou vides
    let nameValid = nameArticle !== null && nameArticle.trim() !== '';
    let recipeValid = recipeArticle !== null && recipeArticle.trim() !== '';
    let imageValid = imageArticle !== null;
    let typeValid = typeArticle !== null && typeArticle.trim() !== '';

    // Définir des valeurs par défaut si les valeurs sont nulles ou vides
    let defaultName = nameValid ? nameArticle : article.name;
    let defaultRecipe = recipeValid ? recipeArticle : article.recipe;;
    let defaultImage = imageValid ? imageArticle : null;
    let defaultType = typeValid ? typeArticle : article.type;

    let formData = new FormData();
    formData.append('id', article.id);
    formData.append('name', defaultName);
    formData.append('recipe', defaultRecipe);
    formData.append('type', defaultType);
    formData.append('link', defaultImage);

    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    fetch(config.API_URL+'/api/data/'+article.id, {
      method: 'POST',
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse the response as JSON
      })
      .then(responseData => {
        console.log('Success:', responseData);
      })
      .catch(error => {
        console.error('Error:', error);
      });

    setIsEditMode(false);
    window.location.reload();
  }

  const handleDelete = () => {    
    fetch(config.API_URL+'/api/data/'+article.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse the response as JSON
      })
      .then(responseData => {
        console.log('Success:', responseData);
        window.location.href = '/';
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Vérifier la taille de l'image
    if (file && file.size > 2097152) { // Limite à 2 Mo (2097152 octets)
      setErrorMessage('L\'image est trop grande. Veuillez sélectionner une image de moins de 1 Mo.');
    } else {
      setSelectedFile(file);
      setErrorMessage('');
    }
  };

  const renderRecipeLines = (recipe) => {
    const lines = recipe.split('\r\n');
    return lines.map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const imageUrl = link !== null && link !== '' ? config.API_URL + '/images' + link : null;
  const typeName = types[article.type-1] != null ? types[article.type-1].name : '';

  return(
    <div>
      <div className={`article ${isEditMode ? 'invisible':''}`}>
        <nav style={{position:'absolute'}}>
          <a href='/'>
            <i className="fa-solid fa-arrow-left"></i>
          </a>
          <button onClick={(e) =>{e.preventDefault(); setIsEditMode(!isEditMode)}} className='button_icon'>
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
        </nav>

        <div id='article_title'>
          <h1>{name}</h1>
          <p className='filtre' style={{margin:'0'}}>{typeName}</p>
        </div>

        <img src={imageUrl} alt=""/>
        <div id="article_recipe">
          <h2>La Recette pour 1 personne :</h2>
          <div>{renderRecipeLines(recipe)}</div>
        </div>
      </div>

      <form className={`article ${isEditMode ? '':'invisible'}`} onSubmit={handleSubmit}>
        <nav>
          <button onClick={(e) => { e.preventDefault();setIsEditMode(!isEditMode)}} className='button_icon'>
            <i className="fa-solid fa-arrow-left"></i></button>
          <button type='button' onClick={() => handleDelete()} className='button_icon'>
            <i className="fa-solid fa-trash error"></i></button>
        </nav>

        <label htmlFor='name'>
          <span>
            Nom
          </span>
          <input id='nameArticle' name='name' placeholder={name}
            value={formData.name}
            onChange={handleChange}/>
        </label>

        <label htmlFor='type'>
          <span>
            Type
          </span>
          <select id='typeArticle' name='type' placeholder={type}
            value={formData.type}
            onChange={handleChange}>
            {types.map((type) => (
              <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
        </label>

        <label htmlFor='recipe'>
          <span>
            Recette
          </span>
          <textarea
            id='recipeArticle'
            name='recipe'
            className='input_text'
            value={formData.recipe}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Empêche le comportement par défaut de la touche "Enter" (éviter la soumission du formulaire)
                const { selectionStart, selectionEnd, value } = e.target;
                const newValue = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;
                e.target.value = newValue;
                e.target.setSelectionRange(selectionStart + 1, selectionStart + 1); // Déplace le curseur après la nouvelle ligne
              }
            }}
          />
        </label>

        <label>
          <span>
            Image Actuelle
          </span>
          <img src={imageUrl} alt="actuellement sur l'article"/>
        </label>

        <label htmlFor='image'>
          <span>
            Image
          </span>
          <input id='imageArticle' name='image' type='file' onChange={handleFileChange}
           accept='image/jpg, image/png, image/webp' />
           {errorMessage && <div style={{ color: 'red', fontSize: '1.5em' }}>{errorMessage}</div>}
        </label>
        <ImagePreview file={selectedFile}/>

        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
          <button type="submit"><i className="fa-solid fa-check"></i></button>
        </div>
      </form>

    </div>
  );
};

export default ArticleForm;
