import React, { useState, useEffect } from 'react';
import config from '../config';
import ImagePreview from './ImagePreview';

const ArticleCreate = () => {
    const [types, setTypes] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hasCreated, setCreated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchTypes = async () => {
      try {
        const response = await fetch(config.API_URL+'/api/types');
        if (!response.ok) {
          throw new Error("Erreur réseau");
        }
        const data = await response.json();
        setTypes(data);
      } catch (error) {
        console.error("Erreur lors de la requête:", error);
      }
    };

    useEffect(() => {
      fetchTypes();
    }, []);
  
  const handleSubmit =  (e) => {
    e.preventDefault();
    let nameArticle = document.getElementById('nameArticle').value;
    let recipeArticle = document.getElementById('recipeArticle').value;
    let imageArticle = document.getElementById('imageArticle').files[0];
    let typeArticle = document.getElementById('typeArticle').value;

    let formData = new FormData();
    formData.append('name', nameArticle);
    formData.append('recipe', recipeArticle);
    formData.append('link', imageArticle);
    formData.append('type', typeArticle);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    const fetchData = async () => {
      try {
        const response = await fetch(config.API_URL + '/api/create', {
          method: 'POST',
          body: formData,
        })
        .catch(error => {
          console.error('Error:', error);
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const responseData = await response.json();
        console.log('Success:', responseData);
        setCreated(true);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    
    fetchData();
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 2097152) { // Limite à 2 Mo (2097152 octets)
      setErrorMessage('L\'image est trop grande. Veuillez sélectionner une image de moins de 1 Mo.');
    } else {
      setSelectedFile(file);
      setErrorMessage('');
    }
  };

  return(
    <div>
      <div className={`pop_up ${hasCreated ?'' : 'invisible'}`}>
        <div>
          <p>Le produit a été créé avec succès !</p>
          <a className='filtre' href='/'>Terminé</a>
        </div>
      </div>

      <form className={'article'} onSubmit={handleSubmit}>
        <nav>
          <a href='/'>
            <i className="fa-solid fa-arrow-left"></i>
          </a>
        </nav>
        <label htmlFor='name'>
          <span>
            Nom
          </span>
          <input id='nameArticle' name='name' />
        </label>

        <label htmlFor='type'>
          <span>
            Type
          </span>
          <select id='typeArticle' name='type'>
            {types.map((type) => (
              <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
        </label>

        <label htmlFor='recipe'>
          <span>
            Recette
          </span>
          <textarea
            id='recipeArticle'
            name='recipe'
            className='input_text'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Empêche le comportement par défaut de la touche "Enter" (éviter la soumission du formulaire)
                const { selectionStart, selectionEnd, value } = e.target;
                const newValue = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;
                e.target.value = newValue;
                e.target.setSelectionRange(selectionStart + 1, selectionStart + 1); // Déplace le curseur après la nouvelle ligne
              }
            }}
          />

        </label>

        <label htmlFor='image'>
          <span>
            Image
          </span>
          <input id='imageArticle' name='image' type='file' 
            accept='image/jpg, image/png, image/jpeg, image/webp' onChange={handleFileChange}/>
            <p>Taille maximale :1000x1000 px</p>
            <p>format : jpg, png, jpeg</p>
            {errorMessage && <div style={{ color: 'red', fontSize: '1.5em' }}>{errorMessage}</div>}
        </label>
        <ImagePreview file={selectedFile}/>

        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
          <button type="submit"><i className="fa-solid fa-check"></i></button>
        </div>      
      </form>

    </div>
  );
};

export default ArticleCreate;