import React, { useState, useEffect } from 'react';

const ImagePreview = ({ file }) => {
  const [imagePreview, setImagePreview] = useState(null);

  // Appelez handleImageChange lorsque la prop file change
  useEffect(() => {
    const handleImageChange = () => {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    };

    handleImageChange();
  }, [file]);

  return (
    <div>
      {imagePreview && (
        <div>
          <h2>Preview de l'image</h2>
          <img src={imagePreview} alt="Preview" />
        </div>
      )}
    </div>
  );
};

export default ImagePreview;