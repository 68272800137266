
const Footer = () => {

     return (
          <footer>
               <div className="footer_contact">
                    Made by Hugo Cluzel
               </div>
          </footer>
     );
};

export default Footer;
