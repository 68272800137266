
import React from 'react';

const NotFound = () => {
     


     return (
     <div>
          <h1>Mauvaise page</h1>
     </div>
     );
};

export default NotFound;
