
const Header = () => {

     return (
          <header>
               <a href="/"><h1>Le Bar du Père !</h1></a>
          </header>
     );
};

export default Header;
