import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

const CardArticle = (props) => {
  const { id, name, link } = props.article;
  const imageUrl = config.API_URL+'/images'+link;
  return (
    <Link to={`/article/${id}`} className="card" key={id}>
      <div className="image-container">
        <img src={imageUrl} alt='cocktail' />
      </div>
      <div className='card_title'>
        <h1>{name}</h1>
      </div>
    </Link>
  );
};

export default CardArticle;
